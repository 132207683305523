import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import PostMeta from '../components/PostMeta';

const Post = props => {
  const { data } = props;
  const post = data.markdownRemark;
  const isPost = post.parent.sourceInstanceName === 'posts';

  return (
    <Layout
      title={post.frontmatter.title}
      gitAuthorTime={post.fields.gitAuthorTime}
      isPost={isPost}
    >
      <article>
        <header>
          <PageHeading>{post.frontmatter.title}</PageHeading>
          {isPost && <PostMeta post={post} />}
        </header>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        {isPost && (
          <footer className="text-lg mt-10 text-center">
            <Link to="/contact/">Have a comment?</Link>
          </footer>
        )}
      </article>
    </Layout>
  );
};

Post.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired
      }).isRequired,
      html: PropTypes.string.isRequired,
      parent: PropTypes.shape({
        sourceInstanceName: PropTypes.string.isRequired
      }).isRequired,
      timeToRead: PropTypes.number.isRequired,
      fields: PropTypes.shape({
        gitAuthorTime: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
      }
      html
      parent {
        ... on File {
          sourceInstanceName
        }
      }
      timeToRead
      fields {
        gitAuthorTime
      }
    }
  }
`;

export default Post;
